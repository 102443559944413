html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  position: fixed;
  font-size: 16px;
  font-family: 'Noto Sans TC', sans-serif;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
body {
  overflow: hidden;
}
.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 700px) {
  .wrapper {
    transform: rotate(90deg);
    transform-origin: bottom left;

    position: relative;
    top: -100vw;

    height: 100vw;
    width: 100vh;
  }
}

.notification-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  display: none;
  align-items: center;
  justify-content: center;
}
.notification {
  z-index: 5;
  overflow: auto;
  max-width: 90%;
  max-height: 90%;
  background: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px 75px;
  font-size: 30px;
}

.notification div {
  text-align: center;
}
.notification span {
  color: #e91e63;
}

.notification-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  margin: 10px;
  cursor: pointer;
  img {
    height: 100%;
    width: 100%;
  }
}
.notification-img.has::after {
  content: '';
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: red;
  border: 2px solid #fff;
}
